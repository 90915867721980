<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body pad-lr-0 ov-h">
            <div class="header_section padd-30 pt-0">
              <div class="back-btn mt-0 mr-2">
                <router-link :to="{ name: 'roles'}"><i class="fa fa-arrow-left" aria-hidden="true"></i></router-link>
              </div> <div class="w-100 t-wrap">
                <h4 class="menu_header">Create Role</h4>
              </div>

              
            </div>
            <hr>
            <div class="create_items_section">
              <form @submit.prevent="createRole">
                <div v-if="errors !=''" >
                  <div class="alert alert-danger">
                    <ul>
                      <li v-for="(err_msg,errindex) in errors" :key="errindex">{{err_msg}}</li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="pl-5 pr-4 pb-4">
                      <div class="setting_content">
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="name">Name:</label>
                            <div class="col-sm-7">
                              <div>
                                <input type="text" class="form-control" v-model="name" @blur="v$.name.$touch">
                              </div>
                              <div class="input_error" v-if="v$.name.$error">
                                <span v-if="v$.name.required.$invalid">Name is required</span>
                              </div>

                           
                            </div>
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-5" for="dm_consumer_secret">Slug:</label>
                            <div class="col-sm-7">
                              <input type="text" class="form-control" v-model="slug" @blur="v$.slug.$touch"/>
                              <div class="input_error" v-if="v$.slug.$error">
                                <span v-if="v$.slug.required.$invalid">Slug is required</span>
                              </div>
                            </div>
                          </div>
                        </div>   
                      <!--  -->
                      </div>
                    </div>
                  </div>
                <!--  -->
                </div>
                <div class="row bt-b">
                  <div class="col-md-12">
                    <div class="form-group ml-2r mt-3">
                      <button type="submit" class="btn btn-orange"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save</span></button>
                    </div>
                  </div>
                </div>
              <!-- end row -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();
export default {
   
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            name: '',
            slug: '',
            errors: '',
            spinner: false,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes..."
        }
    },
    watch:{
        name(){
            if(this.name !==''){
                const name = this.name.replace(/\s/g, '-');
                this.slug = name.toLowerCase();
            }else{
                this.slug = ''
            }
        }

    },
    validations() {
        return {
            name: { required },
            slug: { required },
        }
    },
    methods: {
        async createRole() {
            this.spinner = true;
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            this.errors = '';
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/create-role";
            const payload = {
                'name': this.name,
                'slug': this.slug,
            }
            this.axios.post(url,payload,config).then((response)=>{
                if(response.status === 201){
                    this.spinner = false;
                    this.$router.push({
                        name: 'roles'
                    })
                    toast.success('role created successfully');
                }
            }).catch((error)=>{
                this.spinner =false;
                if(error.response.status === 400){
                    this.errors = error.response.data.errors
                }else if(error.response.status === 401){
                    this.$router.push({
                        name: 'login'
                    });
                }
            });

        },
    },
    mixins: [ReusableDataMixins]
}
</script>
